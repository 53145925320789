import React from "react"
import TestimonialPreview from "./TestimonialPreview"

const TestimonialGrid = ({ reviews, language }) => {
  const lnth = reviews.length
  let reviewsMap = [...reviews]
  const reviewsHalf = reviews.length / 2

  // keep reviews a multiple of 6
  function adjustArrayLength(arr) {
    const remainder = arr.length % 10
    if (remainder !== 0) {
      return arr.splice(0, arr.length - remainder)
    }
    return arr
  }

  if (lnth < 10) {
    reviewsMap = reviewsMap.splice(0, 6)
  } else {
    reviewsMap = adjustArrayLength(reviewsMap)
  }

  let hasCount = `has-${reviewsMap.length}`
  return (
    <div className={`testimonial-wall ${hasCount}`}>
      {reviewsMap.map((t, idx) => (
        <TestimonialPreview
          hoverLeft={idx + 1 > reviewsHalf}
          withHoverCard
          key={t.youtube}
          language={language}
          youtube={t.youtube}
          image={t.mainPhotoPublicId}
          reviewerName={t.reviewerName}
          city={t.city}
          procedure={
            t.otherProcedure ? t.otherProcedureName : t.mainProcedureDone
          }
        />
      ))}
    </div>
  )
}

export default TestimonialGrid
